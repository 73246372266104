<template lang="pug">
.b-container.p-5
  h1 QR Generator
  .qr-gen.d-flex.mb-5
    .static.mr-5
      qrcode-vue(:value="url" size="500" level="H")
      b-input(v-model="url" placeholder="url")
  </template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: "QRGenerator",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      url: "",
    };
  },
};
</script>
