<template lang="pug">
b-navbar.mb-2(toggleable="lg", type="dark", variant="primary")
  b-navbar-brand(to="/") Home
  b-navbar-brand(v-if="isLoggedIn", to="/links") Links
  b-navbar-brand(v-if="isLoggedIn", to="/tools/qr") QR Generator
  b-navbar-toggle(target="nav-collapse")
  b-collapse#nav-collapse(is-nav)
    b-nav-text(v-if="isLoggedIn && user") {{ user.name }}
    b-navbar-nav
      b-nav-item(@click="logout", v-if="isLoggedIn") Logout
</template>

<script>
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { mapGetters } from "vuex";
export default {
  name: "navigation",
  computed: {
    ...mapGetters(["isLoggedIn", "user"]),
  },
  methods: {
    logout() {
      this.$store.commit(AUTH_LOGOUT, this.$msal);
      this.$router.push("/login");
    },
  },
};
</script>
