import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import VueMSAL from "./plugins/msal";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";

Vue.use(VueMSAL, {
  config: {
    auth: {
      clientId: "2ea15c4a-28a1-49f5-b6c5-48a959a10e63",
      authority:
        "https://login.microsoftonline.com/a8b67eee-7062-4a6e-baa9-17cc2b68d611",
    },
  },
  store,
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
