import Vue from "vue";
import VueRouter from "vue-router";
import LinksList from "../views/links/LinksList.vue";
import LinksStats from "../views/links/LinksStats.vue";
import QRGenrator from "../views/tools/QRGenerator.vue";
import Login from "../views/auth/Login.vue";
import store from "../store";

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next();
    return;
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/",
    redirect: "/links",
  },
  {
    path: "/tools/qr",
    name: "QRGenrator",
    component: QRGenrator,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/links",
    name: "Links",
    component: LinksList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/links/:hash/stats",
    name: "LinksStats",
    component: LinksStats,
    // beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
