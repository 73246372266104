import {
  AUTH_SUCCESS,
  HANDLE_MSAL_RESPONSE,
  AUTH_LOGOUT,
} from "../actions/auth";
import axios from "axios";
const Auth = {
  state: {
    token: localStorage.getItem("user-token") || null,
    user: null,
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    user: (state) => state.user,
  },
  actions: {
    [HANDLE_MSAL_RESPONSE]({ commit }, resp) {
      if (resp) {
        commit(AUTH_SUCCESS, resp);
      }
    },
  },
  mutations: {
    [AUTH_SUCCESS]: (state, payload) => {
      state.token = payload.idToken;
      state.user = payload.account;
      localStorage.setItem("user-token", payload.idToken);
      axios.defaults.headers.common.Authorization = `Bearer ${payload.idToken}`;
    },
    [AUTH_LOGOUT]: (state, $msal) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem("user-token");
      delete axios.defaults.headers.common.Authorization;
      $msal.logoutRedirect();
    },
  },
};

export default Auth;
