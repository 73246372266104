<template lang="pug">
.b-container.p-5(v-if="stats.link")
  h1 Stats for {{stats.link.url}}
  .qr-gen.d-flex.mb-5
    .static.mr-5
      h2 QR Code
      qrcode-vue(:value="`https://gofwd.io/${stats.link.hash}`" size="200" level="H")
      span https://gofwd.io/{{stats.link.hash}}
    .dynamic
      h2 QR Code with Source
      qrcode-vue(:value="qrURL" size="200" level="H")
      span {{qrURL}}
      b-input-group(:prepend="`https://gofwd.io/${stats.link.hash}`")
        b-input(v-model="source" placeholder="Source")
  b-table(:items="stats.clicks" :fields="fields")
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { LINK_STATS_REQUEST } from "@/store/actions/link";

export default {
  name: "LinksStats",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      source: "",
      stats: {},
      fields: [
        "timestamp",
        "source",
        "user_agent_browser",
        "user_agent_platform",
        "full_url",
      ],
    };
  },
  methods: {},
  created() {
    this.$store
      .dispatch(LINK_STATS_REQUEST, this.$route.params.hash)
      .then((stats) => {
        this.stats = stats;
      });
  },
  computed: {
    qrURL() {
      const url = `https://gofwd.io/${this.$route.params.hash}`;
      if (this.source != "") {
        return `${url}/${this.source}`;
      } else {
        return url;
      }
    },
  },
};
</script>
