import axios from "axios";
import Vue from "vue";
import { MESSAGE_ADD } from "../actions/messages";
import {
  LINK_LIST_REQUEST,
  LINK_LIST_SUCCESS,
  LINK_STATS_REQUEST,
  LINK_STATS_SUCCESS,
  LINK_CREATE_REQUEST,
  LINK_CREATE_SUCCESS,
  LINK_URL_AVAILABLE,
  LINK_SLUG_AVAILABLE,
} from "@/store/actions/link";

const Link = {
  getters: {
    getLinks: (state) => state.links,
    linkStatus: (state) => state.status,
  },
  state: {
    links: [],
    status: "init",
  },
  actions: {
    [LINK_LIST_REQUEST]: ({ commit }) =>
      new Promise((resolve, reject) => {
        commit(LINK_LIST_REQUEST);
        axios
          .get(`${process.env.VUE_APP_API_BASE_URL}links/`)
          .then((resp) => {
            const links = resp.data;
            commit(LINK_LIST_SUCCESS, links);
            resolve(resp);
          })
          .catch((err) => reject(err));
      }),
    [LINK_STATS_REQUEST]: ({ commit }, hash) =>
      new Promise((resolve, reject) => {
        commit(LINK_STATS_REQUEST);
        axios
          .get(`${process.env.VUE_APP_API_BASE_URL}links/stats/${hash}`)
          .then((resp) => {
            const stats = resp.data;
            commit(LINK_STATS_SUCCESS);
            resolve(stats);
          })
          .catch((err) => reject(err));
      }),
    [LINK_CREATE_REQUEST]: ({ commit }, newLink) =>
      new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}links/add`,
          method: "POST",
          data: newLink,
        })
          .then((resp) => {
            commit(LINK_CREATE_SUCCESS, resp.data);
            commit(MESSAGE_ADD, {
              title: "New Link Created",
              body: `New Link ${resp.data.url} was successfully created!`,
              category: "success",
            });
            resolve(resp);
          })
          .catch((err) => reject(err));
      }),
    [LINK_URL_AVAILABLE]: (context, newURL) =>
      new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_BASE_URL}links/url-available/${newURL}`
          )
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => reject(err));
      }),
    [LINK_SLUG_AVAILABLE]: (context, newSlug) =>
      new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_BASE_URL}links/slug-available/${newSlug}`
          )
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => reject(err));
      }),
  },
  mutations: {
    [LINK_LIST_REQUEST]: (state) => {
      state.status = "loading";
    },
    [LINK_STATS_REQUEST]: (state) => {
      state.status = "loading";
    },
    [LINK_LIST_SUCCESS]: (state, links) => {
      state.status = "loaded";
      Vue.set(state, "links", links);
    },
    [LINK_STATS_SUCCESS]: (state) => {
      state.status = "loaded";
    },
    [LINK_CREATE_REQUEST]: (state) => {
      state.status = "loading";
    },
    [LINK_CREATE_SUCCESS]: (state, newLink) => {
      state.status = "loaded";
      state.links.push(newLink);
    },
  },
};

export default Link;
