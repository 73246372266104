<template lang="pug">
#login
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  created() {
    const token = localStorage.getItem("user-token");
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.$router.push("/links");
    } else {
      const accounts = this.$msal.getAllAccounts();
      if (accounts.length === 0) {
        this.$msal.loginRedirect();
      }
    }
  },
};
</script>
