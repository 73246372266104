import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import MessagesModule from "./modules/messages";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);

import Link from "./modules/link";
import Auth from "./modules/auth";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    Link,
    MessagesModule,
  },
  plugins: [vuexLocal.plugin],
});
