<template lang="pug">
.b-container.p-4
  b-row.d-flex.justify-content-between.pb-4
    b-form(inline, @submit.prevent="createNewLink")
      b-col
        b-form-input#formUrl.m-2(
          v-model="newLink.url"
          placeholder="URL"
          required
          v-on:input="checkURLExists"
          :state="urlValidationStatus"
        )
        b-form-invalid-feedback.text-info(:state="urlValidationStatus") Warning this URL already exists
      b-col
        b-form-input#formSlug.m-2(
          v-model="newLink.slug"
          placeholder="Slug"
          v-on:input="checkSlugExists"
          :state="slugValidationStatus"
        )
        b-form-invalid-feedback.text-danger(:state="slugValidationStatus") Warning this Slug already exists
      b-button.m-2(variant="primary", type="submit") Create New Link
    b-form-group.d-flex.align-items-end.m-2.pr-4(label="Search:" label-for="filterSearch")
      b-input#filterSearch.ml-2(v-model="filterSearch" debounce="500")
  #links
    b-table(:items="searchResults", :fields="fields", :sort-by.sync="sortBy" :sort-desc.sync="sortDesc")
      template(#cell(qr)="data")
        qrcode-vue(:value="`https://gofwd.io/${data.item.hash}`" size="100" level="H")
      template(#cell(actions)="data")
        .d-flex.flex-row.justify-content-around.align-content-center
          router-link(:to="`/links/${data.item.hash}/stats`") Stats
          b-button(v-b-modal="'qr-modal'" variant="primary" @click="viewFullSize(data.item.hash)") Full Size QR
  b-modal.modal-lg(id="qr-modal" dialog-class="dialog-centered" :body-class="['d-flex', 'justify-content-around', 'align-content-center']" content-class="modal-content-centered" size="lg" hide-backdrop hide-footer centered title="QR Code")
    qrcode-vue(:value="`https://gofwd.io/${fullSizeQrModalUrl}`" size="500" level="H")
</template>

<script>
import {
  LINK_LIST_REQUEST,
  LINK_CREATE_REQUEST,
  LINK_URL_AVAILABLE,
  LINK_SLUG_AVAILABLE,
} from "@/store/actions/link";
// import { MESSAGE_ADD } from "@/store/actions/messages";
import { mapGetters } from "vuex";
import _ from "lodash";
import QrcodeVue from "qrcode.vue";

export default {
  name: "LinksList",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      filterSearch: "",
      sortBy: null,
      sortDesc: null,
      urlValidationStatus: null,
      slugValidationStatus: null,
      fullSizeQrModalUrl: null,
      newLink: {
        url: null,
        slug: null,
      },
      fields: [
        "qr",
        {
          key: "created",
          label: "Created At",
          sortable: true,
          formatter: (val) => new Date(Date.parse(val+"Z")).toLocaleString(),
        },
        {
          key: "hash",
          label: "Short URL",
          formatter: (val) => `https://gofwd.io/${val}`,
          sortable: true,
        },
        {
          key: "slug",
          label: "Alternative Slug Url",
          formatter: (val) => (val ? `https://gofwd.io/${val}` : ""),
          sortable: true,
        },
        {
          key: "url",
          label: "URL",
          sortable: true,
        },
        {
          key: "clicks",
          label: "Clicks",
          sortable: true,
        },
        "actions",
      ],
    };
  },
  methods: {
    createNewLink() {
      this.$store.dispatch(LINK_CREATE_REQUEST, this.newLink).then(() => {
        this.newLink.url = null;
        this.newLink.slug = null;
        this.$store.dispatch(LINK_LIST_REQUEST);
      });
    },
    viewFullSize(link) {
      this.fullSizeQrModalUrl = link;
      console.log("showQrModal", link);
    },
    checkURLExists: _.debounce(function () {
      this.$store
        .dispatch(LINK_URL_AVAILABLE, this.newLink.url)
        .then((resp) => {
          this.urlValidationStatus = resp.data;
        });
    }, 750),
    checkSlugExists: _.debounce(function () {
      this.$store
        .dispatch(LINK_SLUG_AVAILABLE, this.newLink.slug)
        .then((resp) => {
          this.slugValidationStatus = resp.data;
        });
    }, 750),
  },
  created() {
    this.$store.dispatch(LINK_LIST_REQUEST);
  },
  computed: {
    ...mapGetters(["getLinks"]),
    searchResults() {
      const toFilter = this.getLinks.filter(
        (link) =>
          JSON.stringify(Object.values(link))
            .toLowerCase()
            .indexOf(this.filterSearch.toLowerCase()) > -1
      );
      return toFilter;
    },
  },
};
</script>

<style lang="scss">
#app {
  .form-inline {
    #formUrl,
    #formSlug {
      width: 15rem;

      &.is-invalid {
        border-color: var(--info);
        background-image: none;
      }
    }

    .invalid-feedback {
      position: absolute;
      margin-top: 0;
      text-align: left;
      padding-left: 1rem;
      font-weight: bold;
    }
  }
}
</style>
