import * as msal from "@azure/msal-browser";
import { HANDLE_MSAL_RESPONSE } from "@/store/actions/auth";

const VueMSAL = {
  async install(Vue, { config, store }) {
    const $msal = new msal.PublicClientApplication(config);
    Vue.prototype.$msal = $msal;
    await $msal.handleRedirectPromise().then((resp) => {
      store.dispatch(HANDLE_MSAL_RESPONSE, resp);
    });
  },
};
export default VueMSAL;
